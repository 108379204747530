@import ../../assets/css/colors.sass
@import ../../assets/css/typography.sass

@font-face
    font-family: 'd77'
    src: url("/../../assets/font/d77.eot") format("embedded-opentype"),url("/../../assets/font/d77.woff2") format("woff2"),url("/../../assets/font/d77.woff") format("woff"),url("/../../assets/font/d77.ttf") format("truetype"),url("/../../assets/font/d77.otf") format("opentype")


.checkout
    width: 100%
    height: calc(100vh - 144px)
    overflow-y: auto
    margin: 0 auto
    padding: 30px 20px
    display: grid
    grid-template-columns: 1fr 250px
    grid-gap: 40px 20px
    align-content: start
    background-color: dark-black20


    &.promo-banner-adjustment
        height: calc(100vh - 185px)

checkout-top-nav
    grid-column: span 2
    display: flex
    align-items: center
    justify-content: space-between
    background-color: black
    padding: 7px 20px
    width: 100%

    //.checkout-top-nav-title
    //    background: $white
    //    color: $white
    //    padding: 8px 14px 0px


.checkout__actions
    display: grid
    grid-auto-flow: column
    grid-gap: 41px

.checkout__steps
    display: grid
    grid-auto-flow: column
    grid-gap: 5px
    justify-content: start
    background-color: black

    .checkout__steps-item
        font-family: 'd37'
        font-size: 1.6em
        color: $black40
        background: url('/assets/img/Bar-icon-3-08.svg') no-repeat top left
        background-size: 63px
        background-color: black
        color: white
        margin-top: 13px
        algin-item: center

        &.checkout__steps-item--active
            background-image: url('/assets/img/Bar-icon-3-09.svg')
            color: $black
            margin-top: 13px
            transition: background-image 0.2s ease-in-out
            color: white

        &.checkout__steps-item--completed
            background-image: url('/assets/img/Bar-icon-3-09.svg')
            color: $black
            margin-top: 13px
            transition: background-image 0.2s ease-in-out

.checkout__sidebar
    background: $white
    padding-bottom: 10px
    display: grid
    grid-template-columns: 1fr
    grid-gap: 20px
    align-content: start
    position: relative



    .checkout__sidebar-title
        background white
        color: white
        padding: 13px 20px 10px


    .checkout__sidebar-item, .checkout__sidebar-price, .checkout__promo-code
        padding: 0 20px

        i
            color: $black40

    .checkout__sidebar-price
        display: flex
        justify-content: space-between

    .checkout__promo-code
        display: grid
        grid-template-columns: 140px auto
        grid-gap: 8px

        h3
            grid-column: span 2

    .checkout__sidebar-divider
        background: $black20
        height: 1px

checkout-bottom-nav
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    padding: 14px 20px
    box-shadow: 0 0 4px rgba(0,0,0,.1)
    background: $footer
    display: flex
    justify-content: space-between
    align-items: center
    grid-column: span 2



    checkout-bottom-nav-item, .checkout-bottom-nav-price, .checkout__promo-code
        padding: 0 20px





.card-grading-text
  display: inline-block
  margin: 0 10px

.bottom-nav--finished
    margin-left: auto

.checkout__warning
    font-family: 'd35'
    font-size: 1.4rem
    margin: 0 20px 0 auto

    img
        margin: 0 5px





.checkout__content
    display: grid
    grid-template-columns: 1fr
    grid-gap: 20px
    padding: 20px

.checkout__grid-items
    display: grid
    grid-template-columns: repeat(auto-fit, 250px)
    grid-gap: 20px

    a
        text-decoration: none

    .item
        background: $white
        border: 1px solid $black20
        padding: 14px 14px 8px
        display: grid
        grid-template-columns: 1fr
        grid-template-rows: auto 40px 1fr
        grid-gap: 14px
        cursor: pointer

        img
            width: 40px
            justify-self: center

        h3
            color: $black
            text-align: center
            align-content: start
            line-height: 2rem
            transition: color 0.5s

        ul
            list-style: disc
            margin-left: 12px
            display: grid
            grid-template-columns: 1fr
            grid-gap: 8px

        &.item--plans
            grid-gap: 7px
            grid-template-rows: 30px
            justify-items: center
            align-content: start

        &.item--recommended
            border-color: $black

            h3
                font-size: 2.6rem

        &:hover
            border-color: $black40

        &.businessdayGur--off
            pointer-events: none

            h3
                color: rgba(0, 0, 0, 0.4)

        &.item--selected
            border-color: $primary
            transition: border-color 0.5s

            h3
                color: $primary

    .item-new
        display: flex
        justify-content: center
        align-items: center
        padding: 8px 14px 8px
        margin-left:5px
        min-height: 240px
        background: $white
        border: 1px solid $black20
        cursor: pointer

        img
            width: 40px
            justify-self: center

        h3
            color: $black
            text-align: center
            align-content: start
            line-height: 2rem
            transition: color 0.5s

        ul
            list-style: disc
            margin-left: 12px
            display: grid
            grid-template-columns: 1fr
            grid-gap: 8px

        &.item--plans
            grid-gap: 7px
            grid-template-rows: 30px
            justify-items: center
            align-content: start

        &.item--recommended
            border-color: $black

            h3
                font-size: 2.6rem

        &:hover
            border-color: $black40

        &.businessdayGur--off
            pointer-events: none

            h3
                color: rgba(0, 0, 0, 0.4)

        &.item--selected
            border-color: $primary
            transition: border-color 0.5s

            h3
                color: $primary

    .button--more
        align-self: center

.checkout__plans
    align-self: end

    h5
        text-align: center
        color: $primary

.checkout__columns
    display: grid
    grid-template-columns: minmax(200px, 600px) minmax(200px, 500px)
    align-items: flex-start
    grid-gap: 10px 40px



    .checkout__search
        display: grid
        grid-gap: 10px

    .checkout__results
        grid-column: 1

        .checkout__results-list
            display: grid
            grid-template-columns: 1fr
            grid-gap: 20px

            a
                cursor: pointer
                color: $primary

    .checkout__items
        grid-column: 2
        grid-row: 1 / span 5 //span 3
        display: grid
        grid-template-columns: 1fr
        grid-gap: 10px

        .checkout__items-empty
            color: $black40
            font-size: 1.4rem

        h4 span
            color: $primary
            font-size: 2.2rem

    .autograph__warning
        padding-top: 40px

.card-result-item, .subject-result-item, .checkout__shipping-item, .checkout__shipping-account-item, .checkout__item
    background: $white
    border: 1px solid $black20
    padding: 10px
    margin-bottom: 12px
    display: grid
    grid-template-columns: auto auto 1fr
    grid-gap: 6px 20px

    h4, .card-result-item__set
        grid-column: span 3

    i
        color: $black40

    &:hover
        border-color: rgba(0, 0, 0, 1.4)
        cursor: pointer

    &.item--selected
        border-color: $primary

.checkout__item
    grid-template-columns: 1fr

.subject-result-item
    grid-template-columns: 1fr

.address-item:hover
    border-color: $black40
    cursor: pointer

.added-card-item
    background: $white
    border: 1px solid $black20
    padding: 10px

    grid-template-columns: 1fr auto
    grid-gap: 10px 15px

    .added-card-item__info
        grid-column: span 2
        display: grid
        grid-gap: 10px

        i
            color: $black40

    .added-card-item__actions
        grid-column: 2
        grid-row: 1
        display: grid
        grid-auto-flow: column
        grid-gap: 10px
        justify-content: end

    .added-card-item__expand
        grid-column: span 2
        justify-self: center
        width: 100%

        &:hover
            background: $black5

        &.expanded
            transform: rotateZ(180deg)

    .added-card-item__options
        grid-column: span 2
        display: flex
        align-items: center
        justify-content: space-between

    &.added-card-item--selected
        border-color: $primary

        h3
            color: $primary

.chip
    border: 1px solid $black20
    border-radius: 5px
    display: flex
    justify-content: space-between
    padding: 8px 10px 3px

.chip-list
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 5px

.checkout__shipping
    display: grid
    grid-gap: 10px

    .checkout__shipping-item
        grid-template-columns: 60px 1fr
        grid-gap: 0px 10px
        align-items: center

        img
            width: 100%
            grid-row: span 2

        &.item--selected
            h5
                color: $primary

.additional-info
    padding-top: 20px
    display: grid
    grid-gap: 8px

    ul
        list-style: disc
        margin-left: 12px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 8px
        font-family: 'd35'
        font-size: 1.3rem

.new-item
    border: 1px dashed $black20
    display: flex
    align-items: center
    justify-content: center
    padding: 20px

    img
        width: 20px

    &:hover
        border-color: $black40
        background: $black5
        cursor: pointer

.checkout__shipping-account
    padding-top: 20px
    display: grid
    grid-gap: 10px

    .checkout__shipping-account-item
        grid-template-columns: 2fr
        grid-gap: 10px
        div
            display: grid
            grid-template-columns: auto 1fr
            grid-gap: 5px
            justify-items: start

        &.item--selected
            h5
                color: $primary

.checkout__review
    border: 1px solid $black20
    background: $white
    padding: 20px
    display: grid
    grid-gap: 6px

    i
        color: $black40

    &.checkout__review--columns
        display: grid
        grid-template-columns: 1fr 41px 1fr 41px 1fr
        grid-gap: 10px

        >*
            grid-row: 2

        h3
            grid-row: 1
            grid-column: span 4

        .button-icon
            grid-row: 1
            justify-self: end

        img
            width: 60px

    &.checkout__review--items
        display: grid
        grid-template-columns: 1fr auto

    .checkout__review-divider
        background: $black20
        width: 1px

    .checkout__review-edit
        display: flex
        align-items: center
        justify-content: space-between

.card-item-list
    grid-column: span 2
    display: grid
    grid-template-columns: repeat(auto-fit, 250px)
    grid-gap: 30px

.card-item-list__item
    border-left: 1px solid $black20
    padding-left: 10px
    display: grid
    grid-gap: 5px
    align-content: start
    height: 100%

    i
        color: $black40

    h4 span
        color: $primary

.order-placed
    border: 1px solid $black20
    background: $white
    padding: 20px
    display: grid
    grid-gap: 10px

    .order-placed__tracking
        border-top: 1px solid $black20
        // border-bottom: 1px solid $black20
        padding: 20px 0 0
        margin: 10px 0
        display: grid
        grid-gap: 10px
        justify-items: start

    .order-placed__note
        font-size: 1.2rem

    .order-placed__learn
        display: grid
        grid-template-columns: 1fr 1fr
        grid-gap: 20px 40px

        h4
            grid-column: span 2

        .order-placed__learn-text
            display: grid
            grid-gap: 10px
            align-content: start

.tracker
    width: 100%
    font-family: 'd77'
    font-size: 1.4rem
    text-transform: uppercase
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
    grid-gap: 1px

    span
        background: $grey-light
        color: $white40
        padding: 6px 0 4px
        text-align: center

    span:first-child
        border-top-left-radius: 4px
        border-bottom-left-radius: 4px

    span:last-child
        border-top-right-radius: 4px
        border-bottom-right-radius: 4px

    .tracker--active
        background: $primary
        color: $white

.search-input-loader
    margin: -32px 8px 0 0
    float: right

.checkout__search, .modal__list

    .input
        width: 100%
