$black: #111111
$black70: rgba(0,0,0,.7)
$black40: rgba(0,0,0,.4)
$black20: rgba(0,0,0,.2)
$black5: rgba(0,0,0,.05)
$grey-dark: #252525
$grey-light: #464646
$grey-super-light: #999999
$white: #ffffff
$footer: #f5f5f5
$white60: rgba(255,255,255,.6)
$white40: rgba(255,255,255,.4)
$primary: #785aff
$primary50: rgba(#785aff, .5)
$error: #D70022
$success: #178765
