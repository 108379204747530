@import ../css/media-queries.sass

@font-face
    font-family: 'd77'
    src: url('/assets/font/d77.woff'), url('/assets/font/d77.woff2')

@font-face
    font-family: 'd37'
    src: url('/assets/font/d37.woff'), url('/assets/font/d37.woff2')

@font-face
    font-family: 'd35'
    src: url('/assets/font/d35.woff'), url('/assets/font/d35.woff2')

html
    font-size: 62.5%

    @media #{$mobile}
        font-size: 70%

h1, h2, h3, h4, h5
    font-family: 'd77'
    margin: 0
    font-weight: normal
    text-transform: uppercase

h1
    font-size: 9rem

h2
    font-size: 5rem
    line-height: 4.2rem

    @media #{$mobile}
        font-size: 3.28rem
        line-height: 3rem

h2 span
    font-family: 'd37'
    font-size: 3rem
    color: $black40
    display: block

h3
    font-size: 2.1rem

h4
    font-size: 1.7rem

h5
    font-size: 1.5rem

p
    font-family: 'd35'
    margin: 0
    font-size: 1.5rem
    line-height: 2.1rem
    font-weight: normal

    @media #{$mobile}
        font-size: 1.4rem
        line-height: 2rem

i
    font-family: 'd37'
    font-size: 1.6rem
    text-transform: uppercase
    font-style: normal
    font-weight: normal

strong
    font-family: 'd77'
    font-weight: normal

    @media #{$mobile}
        font-size: 1.5rem
