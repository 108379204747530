@import ../css/normalize
@import ../css/media-queries.sass
@import ../css/colors.sass
@import ../css/typography.sass
@import "../../../node_modules/@syncfusion/ej2/fabric.css"
@import "../../../node_modules/ngx-ui-switch/ui-switch.component.scss"
@import "../../../node_modules/ngx-toastr/toastr.css"


@font-face
    font-family: 'd77'
    src: url("/../../assets/font/d77.eot") format("embedded-opentype"),url("/../../assets/font/d77.woff2") format("woff2"),url("/../../assets/font/d77.woff") format("woff"),url("/../../assets/font/d77.ttf") format("truetype"),url("/../../assets/font/d77.otf") format("opentype")





.e-grid .e-gridheader thead .e-icons
    font-size: 12px

.switch.checked
    background: #785aff !important
    color: #fff !important
.e-input-group:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error)
    border-color: #785aff
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right
    right: 100px

.e-grid[_ngcontent-c5] .e-frozencontent[_ngcontent-c5] table[_ngcontent-c5] tr[_ngcontent-c5] td[_ngcontent-c5]:first-child:empty, .e-grid[_ngcontent-c5] .e-frozenhdrcont[_ngcontent-c5] table[_ngcontent-c5] tr[_ngcontent-c5] td[_ngcontent-c5]:first-child:empty, .e-grid[_ngcontent-c5] .e-movablecontent[_ngcontent-c5] table[_ngcontent-c5] tr[_ngcontent-c5] td[_ngcontent-c5]:first-child:empty, .e-grid[_ngcontent-c5]:not(.e-grid-min-height) .e-gridcontent[_ngcontent-c5] tr[_ngcontent-c5] td[_ngcontent-c5]:not(.e-indentcell):first-child:empty, .e-grid[_ngcontent-c5]:not(.e-grid-min-height) .e-gridcontent[_ngcontent-c5] tr.e-row[_ngcontent-c5] .e-rowcell[_ngcontent-c5]:empty
   height:1px
.e-grid:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty, .e-grid:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty
   height:1px !important
html
    height: auto !important

body
    -webkit-font-smoothing: antialiased
    -moz-font-smoothing: antialiased
    -o-font-smoothing: antialiased
    color: $black
    height: auto !important

.content
    max-width: 1440px
    width: 100%
    min-height: 48vh
    margin: 60px auto
    display: grid
    grid-template-columns: 100px 1fr 100px
    grid-gap: 120px 0px
    align-content: start

    iframe
        margin: 0 auto 12px

    @media #{$mobile}
        grid-template-columns: 20px 1fr 20px
        grid-gap: 80px 0px
        margin: 30px auto

    &.content--home
        margin: 120px auto

        @media #{$mobile}
            margin: 60px auto

    &.content--compact
        grid-gap: 60px 0px

        @media #{$mobile}
            grid-gap: 30px 0

    &.content--terms
        grid-gap: 30px 0px

    > *
        grid-column: 2 / -2

.avatar
    font-size: 10px
    background: $primary
    width: 4em
    height: 4em
    position: relative

    &::after
        content: ''
        position: absolute
        top: 0
        left: 0
        width: 4em
        height: 4em
        background: url('/assets/img/hexagon.svg') no-repeat top left
        background-size: 4em

    img
        width: 100%

    &.avatar--inverted::after
        background-image: url('/assets/img/hexagon-inverted.svg')

.link
    color: $primary
    cursor: pointer

.link_banner
    color: white
    cursor: pointer

    &:hover
        text-decoration: underline

.button
    background: $primary
    color: $white
    text-transform: uppercase
    border: 0
    border-radius: 4px
    padding: 10px 16px 8px
    font-family: 'd77'
    font-size: 1.3rem
    text-decoration: none
    cursor: pointer

    .button--light-txt
        font-family: 'd37'

    &.button--secondary
        background: $black40

    &.button--arrow
        background: url('/assets/img/button-arrow.svg') no-repeat right 2px
        background-size: 6px
        color: $black40
        padding: 0 10px 0 0

    &.button--large
        font-size: 1.6rem
        padding: 10px 30px 8px

    &.button--inverted
        background: $white
        color: $primary

    &.button--cancel
        background: $white
        color: $error

    &.button--more
        color: $primary
        background: $black5

    &:hover
        opacity: .8

    &.button--disabled
        opacity: .3
        cursor: auto

    &.button--active
        background: $primary

    &.button--full
        width: 100%

.button-icon
    background: none
    border: none
    display: flex
    align-items: center
    justify-content: center
    width: 12px
    height: 18px
    cursor: pointer

    img
        width: 12px

    &.disabled
        cursor: not-allowed

.video
    position: relative
    padding: 25px 0 56.25%
    height: 0

    iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

.table
    display: grid
    grid-template-columns: 1fr
    grid-gap: 20px

    table
        width: 100%
        border: 1px solid $black20
        padding: 10px 20px
        font-family: 'd37'
        font-size: 1.6rem

        @media #{$mobile}
            border: 0
            padding: 0

        tr
            display: grid

            &:nth-child(odd)
                background: $black5

            &:first-child, &.table__level1, &.table__level2, &.table__level3
                background: none

        td
            padding: 10px

            @media #{$mobile}
                padding: 10px 10px 10px 5px

        .table__header
            text-transform: uppercase
            color: $primary
            font-family: 'd77'
            td
                border-bottom: 1px solid $black20

        .table__top25 td:first-child
            border-left: 2px solid $primary

        .table__level1 td:first-child, .table__level2 td:first-child, .table__level3 td:first-child
            background: url('/assets/img/table-level.svg') no-repeat 10px 0px
            padding-left: 24px

        .table__level2 td:first-child
            background-position: 24px 0px
            padding-left: 38px

        .table__level3
            grid-template-columns: 1fr !important

            td:first-child
                background-position: 38px 0px
                padding-left: 52px
                display: grid
                grid-template-columns: repeat(auto-fit, 82px)
                grid-gap: 20px
                cursor: auto

            &:hover
                color: #c0c0c0 !important
                cursor: auto !important

        .table__card
            width: 82px
            text-align: center
            margin: 5px 15px 5px 0
            position: relative
            cursor: pointer

            img
                width: 100%

            .checkbox
                position: absolute
                top: 6px
                right: 6px

            &:hover
                color: #c0c0c0

        &.table--2 tr
            grid-template-columns: 1fr 4fr

        &.table--3 tr
            grid-template-columns: 3fr 2fr 1fr

        &.table--3b tr
            grid-template-columns: 1fr 2fr 4fr

        &.table--4 tr
            grid-template-columns: 1fr 2fr 1fr 4fr

        &.table--4b tr
            grid-template-columns: 4fr 2fr 1fr 1fr

        &.table--4c tr
            grid-template-columns: 1fr 3fr 3fr 2fr

        &.table--5 tr
            grid-template-columns: 5fr 1fr 1fr 1fr 1fr

        &.table--5b tr
            grid-template-columns: 4fr 2fr 1fr 1fr 1fr

        &.table--5c tr
            grid-template-columns:  0.5fr 6fr 2fr 2fr 2.5fr

        &.table--5d tr
            grid-template-columns: 1fr 3fr 3fr 1fr 1fr

        &.table--6 tr
            grid-template-columns: 2fr 2fr 1fr 2fr .5fr 1fr

        &.table--6b tr
            grid-template-columns: 3fr 1fr 2fr 1fr 1fr 1fr

        &.table--7 tr
            grid-template-columns: 1fr 3fr 1fr 2fr 1fr 1fr 1fr

        &.table--8 tr
            grid-template-columns: repeat(4, 1fr)

        &.table--11 tr
            grid-template-columns: repeat(11, 1fr)

        &.table--12 tr
            grid-template-columns: 3fr repeat(10, 1fr) 78px

        &.table--20 tr
            grid-template-columns: repeat(19, 1fr) 1.5fr

        &.table--21 tr
            grid-template-columns: 2fr repeat(5, 2fr) 0.5fr

        &.table--grades
            td
                text-align: center
                font-size: 2rem

            .table__header
                font-family: 'd37'
                color: $black40

        &.table--review
            border: 0
            padding: 0

    .table__note
        border-left: 2px solid $primary
        padding-left: 5px

    .table--selectable
        tr:not(.table__header):hover
            color: #c0c0c0
            cursor: pointer
.followUsSocial
    margin: 30px 0
    min-height: 1px
    width: 100%

    @media #{$mobile}
        padding-left: 2%
        padding-right: 2%

    h2
       padding-right: 46px

       @media #{$mobile}
        padding-right: 50px
        font-size: 25px

       @media #{$mobilexs}
        padding-right: 15px
        font-size: 20px

    img
       width: 38px

       @media #{$mobile}
        width: 30px

       @media #{$mobilexs}
        width: 30px


.followUsSocial_2
    margin: 0 0 30px
    min-height: 1px
    width: 100%

    @media #{$mobile}
        padding-left: 2%
        padding-right: 2%

    h2
       padding-right: 46px

       @media #{$mobile}
        padding-right: 50px
        font-size: 25px

       @media #{$mobilexs}
        padding-right: 15px
        font-size: 20px

    img
       width: 38px

       @media #{$mobile}
        width: 30px

       @media #{$mobilexs}
        width: 30px

.followUsSocial_3
    margin: 30px 0px
    min-height: 1px
    width: 100%

    @media #{$mobile}
        padding-left: 0
        padding-right: 0

    h2


       @media #{$mobile}
        padding-right: 50px
        font-size: 25px

       @media #{$mobilexs}
        padding-right: 15px
        font-size: 20px

    img
       width: 30px

       @media #{$mobile}
        width: 30px

       @media #{$mobilexs}
        width: 30px


.heroBusinessBanner
    background-size: cover
    background: url('/assets/img/woodgrain_banner.jpg') no-repeat center center
    height: 480px
    padding-top: 30px
    position: relative
    overflow: hidden

    @media #{$mobile}
        height: 402px

    h1
       color: #785aff
       text-align: center
       font-size: 99px

       @media #{$mobile}
          font-size: 4.5rem

    h2
       color: #fff
       text-align: center
       padding-top: 5px
       font-size: 50px

       @media #{$mobile}
          font-size: 2.5rem

    h3
       color: #785aff
       text-align: center
       font-size: 152px
       margin-top: -40px

       @media #{$mobile}
          font-size: 70px
          padding-top: 36px

    p
       color: #fff
       text-align: center
       padding-top: 30px
       font-size: 26px

       @media #{$mobile}
          font-size: 1.5rem
          padding-left: 0%
          padding-right: 0%

    button
       padding: 10px 16px 8px

       @media #{$mobile}
          padding: 5px 10px 5px
          font-size: 12px

.hero
    background: url('/assets/img/CardWallpaper.jpg') no-repeat center center
    background-size: cover
    padding: 0px 0 0px 0px
    // height: 530px
    height: 635px
    position: relative
    overflow: hidden

    @media #{$mobile}
        height: 162px

    .hero__video
        position: absolute
        top: 0
        left: 0
        width: 100%
        z-index: 1
        display: flex
        align-items: center
        justify-content: center
        height: 530px

        video
            width: 100%

        @media #{$mobile}
            height: 234px

    .hero__text
        position: absolute
        top: 432px
        left: 65%
        z-index: 2

        @media #{$mobile}
                top: 155px
                left: 66%



        h5, h1
            color: $white
            margin-bottom: 20px
            // width: 400px
            width: 700px
            // line-height: 7rem
            line-height: 8rem

            @media #{$mobile}
                font-size: 4.5rem
                line-height: 3.8rem
                width: 50%

        h5
            line-height: 5rem

        h4
            font-size: 2rem
            // margin-bottom: 20px
            color: $primary

        ul
            list-style-type: inherit
            margin-left: 15px

        li
            color: $primary

        p
            color: $white
            font-size: 1.7rem
            line-height: 2.2rem

            @media #{$mobile}
                font-size: 1.4rem
                line-height: 2rem

        .button
            display: inline-block

            @media #{$mobile}
                margin-top: 10px

.submenu
    padding: 14px 20px
    text-transform: uppercase
    font-family: 'd77'
    font-size: 1.7rem
    background: $grey-dark
    display: grid
    grid-auto-flow: column
    grid-gap: 40px
    justify-content: start

    @media #{$mobile}
        overflow-x: auto

        li:last-child
            padding-right: 20px

    .submenu__item
        text-decoration: none
        color: white
        cursor: pointer

        @media #{$md}
            color: $white
            display: block
            text-align: center
            padding: 10px 0 6px

        span
            font-family: 'd37'

        &:hover
            text-decoration-line: underline
            text-decoration-style: solid
            text-decoration-color: white
            text-decoration-thickness: 3px
            text-underline-position: under

        &.submenu__item--active
            text-decoration-line: underline
            text-decoration-style: solid
            text-decoration-color: white
            text-decoration-thickness: 3px
            text-underline-position: under

        @media #{$md}
            &.submenu__item--active
                color: $white
                background: $grey-light

.services
    display: grid
    grid-template-columns: auto 1fr 1fr
    grid-gap: 70px 50px
    align-items: start
    justify-items: end

    @media #{$mobile}
        display: block

    h2
        grid-row: span 2
        max-width: 510px
        align-self: center

    .services__item
        max-width: 260px

        @media #{$mobile}
            max-width: 100%
            margin-top: 40px

        .services__item-title
            display: flex
            align-items: center

            img
                width: 40px
                margin-right: 14px

            h3
                line-height: 2rem

        p
            margin: 20px 0

            @media #{$mobile}
                margin: 10px 0

.news
    grid-column: 1 / span 2
    position: relative
    width: 95%
    display: grid
    grid-template-columns: 50px 160px 1fr 50px auto 50px
    grid-template-rows: 40px 50px auto 50px
    grid-auto-rows: 50px
    align-items: center

    @media #{$mobile}
        grid-column: 2
        width: 100%
        padding: 20px
        display: flex
        flex-direction: column
        background: $grey-dark

    .news__background
        grid-column: 1 / -2
        grid-row: 2 / span 3
        background: $grey-dark
        height: 100%
        z-index: -1

        @media #{$mobile}
            display: none

    .news__nav
        grid-column: 2
        background: $grey-dark
        height: 100%
        display: grid
        grid-template-columns: 20px 20px
        align-items: center
        justify-content: center
        grid-gap: 30px

    .news__info
        grid-column: 2 / span 2
        grid-row: 2 / span 3
        max-width: 600px
        color: $white

        @media #{$mobile}
            padding: 20px 0 10px

        h4
            font-size: 2rem
            margin-bottom: 20px
            color: $primary

        h3
            font-size: 3rem

        i
            font-size: 1.8rem

        p
            margin: 20px 0
            font-size: 1.7rem
            line-height: 2.2rem

            @media #{$mobile}
                font-size: 1.4rem
                line-height: 2rem

    .news__info_new
        grid-column: 2 / span 2
        grid-row: 2 / span 3
        max-width: 600px
        color: $black

        @media #{$mobile}
            padding: 20px 0 10px

        h4
            font-size: 2rem
            margin-bottom: 20px
            color: $primary

        h3
            font-size: 3rem

        i
            font-size: 1.8rem

        p
            margin: 20px 0
            font-size: 1.7rem
            line-height: 2.2rem

            @media #{$mobile}
                font-size: 1.4rem
                line-height: 2rem

    .news__image, .news__video
        grid-column: 5 / span 2
        grid-row: 3

        @media #{$mobile}
            width: 100%
            order: -1
            margin-top: -40px

    .news__video
        grid-row: 3 / span 3
        width: 600px
        height: 340px

.newsletter
    display: grid
    grid-template-columns: 1fr auto auto 1fr
    grid-gap: 20px 80px
    align-items: start

    h2
        grid-column: 2
        grid-row: span 3
        text-align: right

    input
        grid-column: 3
        width: 300px
        border: 0
        border-bottom: 1px solid $black70
        font-family: 'd35'
        font-size: 1.4rem

    .button
        grid-column: 3

.section-title
    padding: 40px 20px
    background-position: center center
    background-size: cover
    background-repeat: no-repeat

    h2
        color: $white

    &.section-title--culture
        background-image: url('/assets/img/web_thin_banner.jpg')

    &.section-title--howitworks
        background-image: url('/assets/img/section-howitworks.jpg')

    &.section-title--registry
        background-image: url('/assets/img/section-registry.jpg')

    &.section-title--caard
        background-image: url('/assets/img/section-population.jpg')

    &.section-title--about
        background-image: url('/assets/img/web_thin_banner.jpg')

    &.section-title--authcode
        background-image: url('/assets/img/web_thin_banner.jpg')

    &.section-title--businessdayguarantee
        background-image: url('/assets/img/section-businessdayguarantee.jpg')

    &.section-title--cardgrading
        background-image: url('/assets/img/web_thin_banner.jpg')

    &.section-title--collectorsupport
        background-image: url('/assets/img/web_thin_banner.jpg')

    &.section-title--media
        background-image: url('/assets/img/section-media.jpg')

    &.section-title--popreport
        background-image: url('/assets/img/web_thin_banner.jpg')


.section-text
    display: grid
    grid-template-columns: 1fr
    grid-gap: 20px

.page-title-text
    display: grid
    grid-template-columns: auto 1fr
    grid-gap: 80px
    align-items: center

    @media #{$mobile}
        grid-template-columns: 1fr
        grid-gap: 10px

.culture
    grid-template-columns: 600px 1fr
    grid-gap: 50px
    align-items: center

    @media #{$mobile}
        grid-column: 2
        grid-template-columns: 1fr

    .culture__video
        width: 600px

        @media #{$mobile}
            width: 100%

    .culture__text
        padding-right: 20px
        display: grid
        grid-gap: 20px

.team
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
    grid-gap: 60px

    @media #{$mobile}
        grid-template-columns: repeat(1fr)
        grid-gap: 30px

    .team__header
        grid-column: 1 / -1
        display: grid
        grid-template-columns: 1fr 150px 150px 150px
        grid-gap: 10px

        @media #{$mobile}
            grid-template-columns: 1fr
            justify-items: center

        .button
            align-self: center

            @media #{$mobile}
                width: 200px

    .team__item
        display: grid
        grid-template-columns: auto 1fr
        grid-gap: 0px 20px
        justify-items: start
        align-items: center

        .avatar
            font-size: 30px

        .button
            margin-top: 10px

        p
            line-height: 1.6rem

.guarantee
    grid-column: 1 / -1
    display: grid
    grid-template-columns: auto 1fr
    grid-template-rows: 40px 1fr 40px

    @media #{$mobile}
        grid-column: 2
        grid-template-columns: 1fr
        grid-template-rows: auto

    h2, p
        color: $white

    i
        color: $white60

    .guarantee__title
        grid-row: 2
        background: $grey-dark
        padding: 0px 30px
        display: grid
        grid-template-columns: auto auto
        align-content: center
        grid-gap: 0px 10px

        @media #{$mobile}
            background: $grey-light
            padding: 30px 30px 10px
            grid-gap: 0px 20px
            align-content: start

        h2
            max-width: 300px

        img
            grid-row: span 2
            width: 80px
            margin-top: 5px

            @media #{$mobile}
                margin-top: 0

    .guarantee__text
        grid-row: span 3
        background: $grey-light
        padding: 60px
        box-shadow: 0px 0px 20px rgba(0,0,0,.4)
        display: grid
        grid-template-columns: 1fr
        grid-gap: 30px

        @media #{$mobile}
            box-shadow: none
            padding: 20px

.help
    display: grid
    grid-template-columns: 1fr auto auto 1fr
    grid-gap: 20px 80px
    align-items: start
    justify-items: start

    @media #{$mobile}
        grid-template-columns: 1fr

    h2
        grid-column: 2
        grid-row: span 2
        text-align: right

        @media #{$mobile}
            grid-column: 1
            grid-row: 1
            text-align: left
            grid-gap: 10px

    .button
        grid-column: 3

        @media #{$mobile}
            grid-column: 1

.how-it-works
    display: grid
    grid-template-columns: 1fr

    .how-it-works__right, .how-it-works__left
        width: 51.6%
        display: grid
        grid-template-columns: auto auto
        grid-gap: 0px 20px
        align-items: start

        @media #{$mobile}
            width: 100%

            .how-it-works__text
                padding-top: 10px

        h3
            height: 48px
            display: flex
            align-items: center

            @media #{$mobile}
                height: auto

        p
            margin: 8px 0 20px

        .video
            margin-bottom: 20px

        &:last-child
            .how-it-works__step::before
                background: none

            .video
                margin: 0

    .how-it-works__step
        grid-row: span 3
        background: url('/assets/img/how-it-works-step.svg') no-repeat top center
        background-size: 100%
        width: 40px
        height: 100%
        display: flex
        justify-content: center
        position: relative

        h3
            color: $white

            @media #{$mobile}
                height: 48px

        &::before
            background: $black
            content: ''
            position: absolute
            top: 0
            left: 19px
            width: 2px
            height: 100%
            z-index: -1

    .how-it-works__right
        justify-self: end

        @media #{$mobile}
            justify-self: start

    .how-it-works__left
        h3
            justify-content: flex-end

            @media #{$mobile}
                justify-content: flex-start

        p
            text-align: right

            @media #{$mobile}
                text-align: left

        .how-it-works__step
            order: 1

            @media #{$mobile}
                order: 0

.grading-scale
    display: grid
    grid-template-columns: 1fr auto
    grid-gap: 30px

    .button
        align-self: center

    p
        grid-column: 1 / -1

    .grading-scale__table
        grid-column: 1 / -1
        display: grid
        grid-template-columns: 390px 1fr
        grid-gap: 10px 40px

        @media #{$mobile}
            grid-template-columns: 1fr
            grid-gap: 20px

        .grading-scale__values
            display: grid
            grid-template-columns: repeat(auto-fit, 70px)
            grid-gap: 10px

            @media #{$mobile}
                grid-template-columns: repeat(4, 1fr)

            li
                background: $grey-dark
                font-family: 'd37'
                font-size: 2rem
                color: $white
                text-transform: uppercase
                padding: 18px 14px 16px
                display: flex
                align-items: center
                justify-content: center

                &.grading-scale__values--selected
                        background: #ffffff
                        color: #252525

                &:hover
                    background: #c0c0c0
                    cursor: pointer

            &.grading-scale__values--uniform
                grid-template-columns: repeat(auto-fit, 122px)
                align-content: start

                @media #{$mobile}
                    grid-template-columns: repeat(3, 1fr)

                li
                    height: 122px

                    @media #{$mobile}
                        height: auto

        .grading-scale__description
            p
                margin-bottom: 20px

            i
                color: $black40

.search
    display: grid
    grid-template-columns: 1fr
    grid-gap: 30px

    .search__categories
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr))
        grid-gap: 10px

        li
            border: 1px solid $black20
            padding: 15px 10px
            display: grid
            grid-template-columns: 1fr
            justify-items: center
            grid-gap: 10px

            img
                width: 60px

            &:hover
                background: #c0c0c0
                border-color: #c0c0c0
                cursor: pointer

                img, h3
                    filter: invert(1) brightness(100)

    .search__input
        display: grid
        grid-template-columns: 1fr auto
        grid-gap: 10px

.input
    border: 1px solid $black20
    background: $white
    font-family: 'd37'
    font-size: 1.8rem
    padding: 10px
    appearance: none

    &::-ms-expand
        display: none

    &.input--small
        padding: 5px 10px

    &.input--submitted
        border-color: $primary

.input--error
    border: 1px solid rgba(255,0,0,0.2)

.select
    position: relative

    &:after
        content: ''
        position: absolute
        top: 0
        right: 0
        pointer-events: none
        width: 30px
        height: 100%
        background: url('/assets/img/expand.svg') no-repeat center center

    .input
        width: 100%
        border-radius: 0

.toggle
    font-family: 'd35'
    font-size: 1.4rem
    color: $black
    cursor: pointer
    position: relative
    padding-left: 20px
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    input
        position: absolute
        opacity: 0
        cursor: pointer

    .toggle__check
        position: absolute
        top: 0
        left: 0
        height: 14px
        width: 14px
        background-color: $black20


    .toggle__check_2
        position: absolute
        top: 0
        left: 0
        height: 14px
        width: 14px
        background-color: $black20
        outline: 1px solid #785aff

        &:after
            content: ""
            position: absolute
            display: none
            left: 5px
            top: 2px
            width: 2px
            height: 6px
            border: solid white
            border-width: 0 2px 2px 0
            -webkit-transform: rotate(45deg)
            -ms-transform: rotate(45deg)
            transform: rotate(45deg)

    input:checked ~ .toggle__check
        background-color: $primary

    input:checked ~ .toggle__check:after
        display: block

.tabs
    display: grid
    grid-auto-flow: column
    justify-content: start
    grid-gap: 20px

    li
        font-family: 'd77'
        font-size: 1.4rem
        text-transform: uppercase
        color: $black40
        cursor: pointer

        &:hover
            color: #c0c0c0

    .tabs--active
        color: $primary

.results
    display: grid
    grid-template-columns: 1fr auto
    grid-gap: 20px
    align-items: center

    .results__item
        grid-column: 1 / -1
        border: 1px solid $black20
        padding: 10px 20px

        .results__item-title
            display: grid
            grid-template-columns: 1fr auto
            align-items: center
            cursor: pointer

            i
                order: 2

            img
                order: 1
                grid-row: span 2
                opacity: .4
                width: 14px

            &:hover
                img
                    opacity: 1
                    fill: #c0c0c0

        &.results__item--expanded
            border-color: $primary

            img
                transform: rotateZ(180deg)

        &.results__item--selectable:hover
            cursor: pointer
            border-color: #c0c0c0

    &.results--inline table
        border: 0
        padding: 10px 0 0

.ranking
    tr
        grid-template-columns: 4fr 2fr 1fr 1fr 1fr

.columns
    display: grid

    &.columns--sidebar-right
        grid-template-columns: 1fr 41px 225px

    &.columns--sidebar-left
        grid-template-columns: 150px 1fr

        @media #{$mobile}
            grid-template-columns: 1fr

    &.columns--2
        grid-template-columns: 400px 41px 400px

    .columns__divider
        background: $black20
        width: 1px
        justify-self: center

    .columns__sidebar
        display: grid
        grid-template-columns: 1fr
        grid-gap: 20px
        align-content: start

.card-set
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr))
    grid-gap: 20px

    .card-set__item
        border: 1px solid $black20
        position: relative
        padding: 5px
        display: grid
        grid-template-columns: 95px auto auto 1fr
        grid-gap: 0px
        align-items: center

        img
            width: 80px
            cursor: pointer
            grid-row: span 3

        .checkbox
            position: absolute
            top: 15px
            right: 15px

        i, h5
            color: $black40
            display: block

        h5, h3
            grid-column: span 3

        p
            font-size: 2rem

        h3
            font-size: 3rem

        .card-set__info
            margin-right: 20px

        .card-set__value-up, .card-set__value-down
            color: $success
            background: url('/assets/img/value-up.svg') no-repeat center right
            padding-right: 14px
            display: inline-block

        .card-set__value-down
            color: $error
            background-image: url('/assets/img/value-down.svg')

.checkbox
    display: flex
    align-items: center

    .checkbox__input
        width: 15px
        height: 15px
        border: 1px solid $black20
        background: white
        position: relative
        border-radius: 50%

        label
            display: block
            width: 11px
            height: 11px
            cursor: pointer
            position: absolute
            top: 1px
            left: 1px
            z-index: 1
            background: $primary
            border-radius: 50%
            opacity: 0

        input[type=checkbox]
            visibility: hidden

            &:checked + label
                opacity: 1

.make-offer
    h3
        display: flex
        align-items: center
        justify-content: space-between
        cursor: pointer

        img
            width: 14px
            opacity: .4

        &:hover img
            opacity: 1

    .make-offer__content
        margin-top: 10px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 14px

        i
            color: $black40

        p
            font-size: 1.4rem
            line-height: 1.7rem

        strong
            font-size: 1.6rem

    .make-offer__item
        display: grid
        grid-template-columns: 1fr auto
        grid-gap: 0px 10px
        align-items: center

        p
            grid-column: span 2

    &.make-offer--expanded
        position: sticky
        top: 120px

        h4 img
            transform: rotateX(180deg)

.set-information
    display: grid
    grid-template-columns: 1fr
    grid-gap: 14px

    i
        color: $black40

    .set-information__valuation
        display: flex
        flex-wrap: wrap
        justify-content: space-between

    .set-information__bar-chart
        display: flex
        justify-content: space-between
        align-items: baseline
        flex-wrap: wrap

        .set-information__bar
            background: $black20
            height: 6px
            width: 100%
            border-radius: 10px

            .set-information__bar-fill
                background: $primary
                height: 6px
                border-radius: 10px

.modal
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100vw
    height: 100vh
    background: $black40
    z-index: 100
    display: flex
    align-items: center
    justify-content: center
    animation: fade-in 0.3s

    .modal__close
        position: absolute
        top: 20px
        right: 20px
        cursor: pointer

    .modal__container
        background: white
        min-width: 510px
        max-width: 90vw
        padding: 40px 25px
        max-height: 90vh
        overflow-y: auto
        position: relative

        @media #{$mobile}
            min-width: 80vw
            max-width: 95vw
            max-height: 95vh
            padding: 30px 20px



    .modal__container_2
        background: black
        min-width: 510px
        max-width: 90vw
        padding: 40px 25px
        max-height: 90vh
        overflow-y: auto
        position: relative

        @media #{$mobile}
            min-width: 80vw
            max-width: 95vw
            max-height: 95vh
            padding: 30px 20px

    .modal__actions
        display: flex
        justify-content: space-between

        .f-left
            margin: 0 auto 0 10px

    .columns
        height: 50vh
        overflow-y: auto

    .modal__content
        margin: 20px 0

        .shipping-account-alert
            width: 510px

    .modal__list
        display: grid
        grid-gap: 10px
        align-items: start
        align-content: start

        .modal__list-columns
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 10px

.card-details
    display: grid
    grid-template-columns: 250px 250px 1fr
    grid-gap: 20px
    align-items: center

    img
        width: 100%

    .card-details__info
        display: grid
        grid-template-columns: auto 1fr
        grid-gap: 20px

        h2
            grid-column: span 2

        .card-details__description
            grid-column: span 2

        i
            color: $black40

.bio-details
    display: grid
    grid-template-columns: auto 1fr
    grid-gap: 20px

    @media #{$mobile}
        grid-template-columns: 1fr

    .avatar
        font-size: 30px

    .bio-details__info
        display: grid
        grid-template-columns: auto 1fr
        grid-gap: 20px 40px

        @media #{$mobile}
            grid-template-columns: 1fr
            grid-gap: 0

        h2
            grid-column: span 2
            margin-top: 30px

            @media #{$mobile}
                grid-column: 1
                margin-top: 0

        .bio-details__description
            grid-column: span 2
            white-space: pre-line

        i
            color: $black40

.new-site__modal
    display: grid
    grid-gap: 10px
    justify-items: center

    p
        text-align: center
        max-width: 500px
        white-space: pre-line

    .button
        margin-top: 20px

.caard
    border-bottom: 4px solid $primary
    padding-bottom: 40px
    display: grid
    grid-template-columns: 250px 1fr
    grid-gap: 10px 40px
    align-items: center
    justify-items: start

    img
        width: 48px
        grid-column: span 2

    h2
        grid-row: span 2
        font-size: 4rem
        line-height: 3.5rem

.caard-features
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 60px

    h2
        grid-column: span 2

    .caard-features__item
        display: grid
        grid-template-columns: 60px 1fr
        grid-template-rows: auto 1fr
        grid-gap: 10px 14px

        img
            grid-row: span 2
            width: 100%

.news-list
    display: grid
    grid-template-columns: 1fr
    grid-gap: 40px

    .news-list__item
        display: grid
        grid-template-columns: 250px 1fr
        grid-template-rows: auto auto 1fr
        grid-gap: 10px 20px
        justify-items: start
        align-items: start

        @media #{$mobile}
            grid-template-columns: 1fr

        img
            grid-row: span 3
            width: 100%

.news-interior
    img
        float: left
        margin: 0 40px 40px 0

        @media #{$mobile}
            float: none
            width: 100%

    h3
        margin-bottom: 20px

    p
        margin-bottom: 20px

    ul
        margin-bottom: 20px

    li
        p
            margin-bottom: 0px

.event
    display: grid
    grid-template-columns: 60px 1fr
    grid-gap: 10px 20px
    justify-items: start

    .event__date
        background: url('/assets/img/how-it-works-step.svg') no-repeat top center
        background-size: 100%
        width: 100%
        height: 100%
        display: grid
        grid-template-columns: 1fr
        align-content: start
        justify-items: center
        padding-top: 12px
        position: relative

        h3, h5
            color: $white

        &::before
            background: $black
            content: ''
            position: absolute
            top: 0
            left: 29px
            width: 2px
            height: 100%
            z-index: -1

    .event__info
        padding: 15px 0 40px
        display: grid
        grid-template-columns: 1fr
        grid-gap: 10px
        justify-items: start

    .event__icon
        display: grid
        grid-template-columns: auto 1fr
        grid-gap: 10px
        align-items: center

    .event__note
        font-style: italic

    &:last-child .event__date::before
        background: none

.recommended-sites, .contact-us__list
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
    grid-gap: 20px

    ul
        border: 1px solid $black20
        padding: 10px 20px

        li
            padding: 5px 0

    i
        display: block

.grid-list
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
    grid-gap: 20px

    >div
        border-left: 1px solid $black20
        padding-left: 10px

    h3, .grid-list__note
        grid-column: 1 / -1

    i
        color: $black40
        display: block
        margin-top: 6px

.loader
    background: url('/assets/img/iso.svg') no-repeat center center
    background-size: 20px
    width: 24px
    height: 24px
    animation: loader 1s ease-in-out infinite

    &.m
        background-size: 40px
        width: 50px
        height: 50px

    &.xl
        background-size: 200px
        width: 240px
        height: 240px

    &.invert-color
        filter: invert(100%)

.centered-overlay
    display: flex
    width: 100vw
    height: 100vh
    align-items: center
    align-content: center
    justify-content: center

@keyframes loader
    0%
        transform: rotateY(0deg)
    100%
        transform: rotateY(360deg)

.welcome
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

    .welcome-text
        font-size: 16px
        color: $black70
        animation-fill-mode: forwards
        animation: delayed-text 5s ease-in-out

@keyframes delayed-text
    0%
        opacity: 0
    50%
        opacity: 0
    100%
        opacity: 1

.overlay
    display: flex
    align-items: center
    align-content: center
    justify-content: center
    width: 100%
    position: absolute
    background: rgba(#eaeaea, .7)
    height: 100%
    opacity: 0
    transition: opacity .2s

    &.active
        opacity: 1
        transition: opacity 1s

.more-info
    width: 14px
    height: 14px
    margin: 2px 0 0 5px
    background: url('/assets/img/info.svg') no-repeat 0px 0px
    position: relative

    .more-info__popup
        position: absolute
        top: -20px
        left: 20px
        background: white
        line-height: 18px
        padding: 12px 15px
        box-shadow: 5px 4px 4px 0px $black20
        width: 300px
        display: none
        z-index: 10

        .more-info__title
            font-size: 16px
            font-weight: 600
            margin-bottom: 5px
            color: $black

        .more-info__text
            font-size: 14px
            font-weight: 300
            color: $black

    &:hover
        .more-info__popup
            display: block

.feature-not-available
    display: grid
    grid-gap: 10px
    justify-items: center

    p
        text-align: center
        max-width: 500px

    .button
        margin-top: 20px

.fade-in
    animation: fade-in 1s ease-in-out

    &.quick
        animation: fade-in .4s ease-in-out

@keyframes fade-in
    0%
        opacity: 0
    100%
        opacity: 1

.loader-container
    position: relative

.overlay-results
    position: relative

    .input
        width: 100%

    .overlay-results__list
        position: absolute
        z-index: 10
        background: $white
        width: 100%
        max-height: 200px
        overflow-y: auto

        li
            background: $black5
            padding: 5px 10px

            &:hover
                cursor: pointer
                background: #c0c0c0

@media #{$mobile}
    .mobile-hide
        display: none

.internet-explorer-warning
    padding: 40px

    h2
        margin-top: 20px

    p
        margin: 10px 0 20px

    span
        margin-left: 10px
        color: $black40

.holder-img__hexagons
    img
        max-width: 7px
.w-100-flex
    display: flex
    width: 100%
    flex-wrap: wrap

.w-65
    width: 65%
    flex: 65%
    padding-right: 1rem

    @media #{$mobile}
        width: 100%
        flex: 100%
.w-35
    width: 35%
    flex: 35%
    @media #{$mobile}
        width: 100%
        flex: 100%

.d-box
    display: -webkit-box

    @media #{$mobile}
        display: block

.d-flex
    display: flex
    column-gap: 10px

    @media #{$md}
        display: block

.w-155
    width: 155%

    @media #{$md}
        width: auto


.overflow-x-auto
    width: 100%
    overflow-x: auto

    .contents
    max-width: 1440px
    width: 100%
    min-height: 25vh
    margin: 60px auto
    display: grid
    grid-template-columns: 100px 1fr 100px
    grid-gap: 120px 0px
    align-content: start

    iframe
        margin: 0 auto 12px

    @media #{$mobile}
        grid-template-columns: 20px 1fr 20px
        grid-gap: 80px 0px
        margin: 30px auto

    &.content--home
        margin: 120px auto

        @media #{$mobile}
            margin: 60px auto

    &.content--compact
        grid-gap: 60px 0px

        @media #{$mobile}
            grid-gap: 30px 0

    &.content--terms
        grid-gap: 30px 0px

    > *
        grid-column: 2 / -2


.content_media
    max-width: 1440px
    width: 100%
    min-height: 48vh
    margin: 60px auto
    display: block
    grid-template-columns: 100px 1fr 100px
    grid-gap: 120px 0px
    align-content: start

    iframe
        margin: 0 auto 12px

    @media #{$mobile}
        grid-template-columns: 20px 1fr 20px
        grid-gap: 80px 0px
        margin: 30px auto

    &.content--home
        margin: 120px auto

        @media #{$mobile}
            margin: 60px auto

    &.content--compact
        grid-gap: 60px 0px

        @media #{$mobile}
            grid-gap: 30px 0

    &.content--terms
        grid-gap: 30px 0px

    > *
        grid-column: 2 / -2



.services_new
    display: grid
    grid-template-columns: auto 1fr 1fr
    grid-gap: 70px 50px
    align-items: start
    justify-items: end
    padding-top: 40px
    padding-bottom: 40px
    color: white

    @media #{$mobile}
        display: block

    h2
        grid-row: span 2
        max-width: 510px
        align-self: center

    .services__item
        max-width: 260px

        @media #{$mobile}
            max-width: 100%
            margin-top: 40px

        .services__item-title
            display: flex
            align-items: center

            img
                width: 40px
                margin-right: 14px

            h3
                line-height: 2rem

        p
            margin: 20px 0

            @media #{$mobile}
                margin: 10px 0


.services_new_2
    display: grid
    grid-template-columns: auto 1fr 1fr
    grid-gap: 70px 50px
    align-items: start
    justify-items: end
    color: white

    @media #{$mobile}
        display: block

    h2
        grid-row: span 2
        max-width: 510px
        align-self: center

    .services__item
        max-width: 260px

        @media #{$mobile}
            max-width: 100%
            margin-top: 40px

        .services__item-title
            display: flex
            align-items: center

            img
                width: 95px
                margin-right: 14px

            h3
                line-height: 2rem

        p
            margin: 20px 0

            @media #{$mobile}
                margin: 10px 0


.hero__2
    background: url('/assets/img/SGC_Ruth_Jersey_Banner.jpg') no-repeat center center
    background-size: cover
    padding: 140px 0 80px 40px
    // height: 530px
    height: 570px
    position: relative
    overflow: hidden

    @media #{$mobile}
        height: 234px

    .hero__video__2
        position: absolute
        top: 0
        left: 0
        width: 100%
        z-index: 1
        display: flex
        align-items: center
        justify-content: center
        height: 530px

        video
            width: 100%

        @media #{$mobile}
            height: 234px

    .hero__text__2
        position: absolute
        top: 140px
        left: 40px
        z-index: 2

        @media #{$mobile}
            top: 30px
            left: 20px

        h5, h1
            color: $white
            // width: 400px
            width: 600px
            // line-height: 7rem
            line-height: 8rem

            @media #{$mobile}
                font-size: 4.5rem
                line-height: 3.8rem
                width: 50%

        h5
            line-height: 5rem

        .button
            display: inline-block
            margin-top: 20px

            @media #{$mobile}
                margin-top: 10px

.m-auto
    margin: 0 auto!important

.pd-top
    padding-top: 70px



.content_landing
    max-width: 100%
    width: 100%
    min-height: 48vh
    margin: 60px auto
    display: grid
    grid-template-columns: 100px 1fr 100px
    grid-gap: 120px 0px
    align-content: start

    iframe
        margin: 0 auto 12px

    @media #{$mobile}
        grid-template-columns: 20px 1fr 20px
        grid-gap: 80px 0px
        margin: 30px auto

    &.content--home
        margin: 120px auto

        @media #{$mobile}
            margin: 60px auto

    &.content--compact
        grid-gap: 60px 0px

        @media #{$mobile}
            grid-gap: 30px 0

    &.content--terms
        grid-gap: 30px 0px

    > *
        grid-column: 2 / -2


.modal_2
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100vw
    height: 100vh
    background: $black40
    z-index: 100
    display: flex
    backdrop-filter: blur(5px)
    align-items: center
    justify-content: center
    animation: fade-in 0.3s

    .modal__close
        position: absolute
        top: 20px
        right: 20px
        cursor: pointer

    .modal__container
        background: white
        min-width: 510px
        max-width: 90vw
        padding: 40px 25px
        max-height: 90vh
        overflow-y: auto
        position: relative

        @media #{$mobile}
            min-width: 80vw
            max-width: 95vw
            max-height: 95vh
            padding: 30px 20px



    .modal__container_2
        background: #1616164f
        min-width: 450px
        max-width: 25vw
        padding: 35px 0 0
        max-height: 100vh
        overflow-y: auto
        position: relative

        @media #{$mobile}
            min-width: 80vw
            max-width: 90vw
            max-height: 95vh
            padding: 30px 0 0

    .modal__actions
        display: flex
        justify-content: space-between

        .f-left
            margin: 0 auto 0 10px

    .columns
        height: 50vh
        overflow-y: auto

    .modal__content
        margin: 20px 0

        .shipping-account-alert
            width: 510px

    .modal__list
        display: grid
        grid-gap: 10px
        align-items: start
        align-content: start

        .modal__list-columns
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 10px

.toast-title
    font-weight: bold
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
    height: 22px
    font-size: 14px

.toast-message
    word-wrap: break-word
    font-weight: normal
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
    font-size: 14px
